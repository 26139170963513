import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const RightOfWay = () => (
  <Layout>
    <SEO title="Title Audit" />
    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Title Audit</h1>

        <p>
          Our title experts are extremely knowledgeable about the various parish
          and county reporting systems found across the country. We can provide
          comprehensive title audits for work already done by other firms if
          you've found discrepencies or have concerns about the quaity of the
          work product they are providing. We can verify accurary of surface
          ownerships, mineral interests, encumbrances—such as liens and
          judgments as well as complex chains of title & heirship tracts.{" "}
        </p>
      </div>
    </div>
  </Layout>
)

export default RightOfWay
